var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"projects-page mx-2 mx-md-0"},[_vm._m(0),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"px-1 my-3",attrs:{"cols":"12"}},[_vm._v(" Some projects I have made... ")])],1),_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.projectIds),function(item){return _c('v-col',{key:item,staticClass:"pa-1",attrs:{"cols":"12","sm":_vm.projects[item].sm,"md":_vm.projects[item].md}},[_c('v-hover',{style:(("background: url(\"" + (_vm.projects[item].image) + "\") rgba(0,0,0,0.2); background-blend-mode: overlay; background-size: cover; background-position: center; background-repeat: no-repeat;")),attrs:{"close-delay":"1000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"tile":"","height":"300"},on:{"mouseleave":function($event){return _vm.handleMouseLeave(item)},"mouseenter":function($event){return _vm.handleMouseEnter(item)}}},[_c('v-overlay',{attrs:{"color":"blue","absolute":"","value":hover}},[_c('div',{staticClass:"d-flex flex-column text-center"},[_c('div',{staticClass:"font-weight-bold animate__animated",class:{
                  animate__bounceIn: hover,
                  animate__bounceOut: _vm.projects[item].isLeaving
                },staticStyle:{"font-size":"1.5rem"}},[_vm._v(" "+_vm._s(_vm.projects[item].name)+" ")]),_c('div',{staticClass:"mb-12 animate__animated font-weight-medium",class:{
                  animate__bounceIn: hover,
                  animate__bounceOut: _vm.projects[item].isLeaving
                }},[_vm._v(" "+_vm._s(_vm.projects[item].tech)+" ")]),_c('div',{staticClass:"mt-12 animate__animated",class:{
                  animate__bounceIn: hover,
                  animate__bounceOut: _vm.projects[item].isLeaving
                }},[(_vm.projects[item].link)?_c('v-btn',{attrs:{"small":"","outlined":"","tile":"","href":_vm.projects[item].link,"target":"_blank"}},[_c('b',[_vm._v("Visit site")]),_c('v-icon',{attrs:{"right":"","x-small":""}},[_vm._v("fas fa-external-link-alt ")])],1):_vm._e()],1)])])],1)]}}],null,true)})],1)}),1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"px-1 my-3",attrs:{"cols":"12"}},[_c('div',{staticStyle:{"max-width":"600px"}},[_vm._v(" Plus many more ")])])],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex justify-center mt-16 mb-12",staticStyle:{"font-size":"3rem"}},[_c('div',{staticClass:"animate__animated animate__bounceInUp",staticStyle:{"border-bottom":"4px solid #2196F3"}},[_vm._v(" Projects ")])])}]

export { render, staticRenderFns }