var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"about-page mx-2 mx-md-0"},[_vm._m(0),_c('div',{staticClass:"d-flex flex-column flex-md-row justify-space-between"},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"align-self-center  animate__animated animate__zoomIn"},[_c('v-hover',{attrs:{"close-delay":"800"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-img',{staticClass:"rounded-circle profile-picture",class:{ 'animate__animated animate__jello': hover },attrs:{"height":"200","width":"200","aspect-ratio":"1","src":require('@/assets/profile_picture.jpg'),"alt":"Enar"}})]}}])})],1),_vm._m(1),_c('div',{staticClass:"align-self-center mt-2 animate__animated animate__zoomIn"},_vm._l((_vm.socials),function(btn){return _c('v-tooltip',{key:btn.name,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"href":btn.link,"target":btn.target,"icon":"","large":"","color":"blue","aria-label":btn.name}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(btn.icon))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(btn.name))])])}),1)]),_c('div',{staticClass:"d-flex flex-column flex-sm-row flex-md-column my-14 my-md-0"},[_c('div',{staticClass:"d-flex flex-column flex-sm-row justify-space-around my-2"},[_c('v-hover',{attrs:{"close-delay":"800"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"mx-2 my-2 my-sm-0 text-center animate__animated animate__zoomIn"},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-card',{staticClass:"mb-2 pa-3",class:{ 'animate__animated animate__wobble': hover },attrs:{"color":"blue","dark":"","shaped":""}},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("fas fa-desktop")])],1)],1),_c('div',{staticClass:"font-weight-bold",staticStyle:{"font-size":"1.5rem"}},[_vm._v(" Responsive ")]),_c('div',{staticClass:"mx-auto",staticStyle:{"max-width":"225px"}},[_vm._v(" Pages will work on any screen size and on any device ")])])]}}])}),_c('v-hover',{attrs:{"close-delay":"800"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"mx-2 my-2 my-sm-0 text-center animate__animated animate__zoomIn"},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-card',{staticClass:"mb-2 pa-3",class:{ 'animate__animated animate__wobble': hover },attrs:{"color":"blue","dark":"","shaped":""}},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("fas fa-chart-line")])],1)],1),_c('div',{staticClass:"font-weight-bold",staticStyle:{"font-size":"1.5rem"}},[_vm._v(" Dynamic ")]),_c('div',{staticClass:"mx-auto",staticStyle:{"max-width":"225px"}},[_vm._v(" Web pages will come alive ")])])]}}])})],1),_c('div',{staticClass:"d-flex flex-column flex-sm-row justify-space-around my-2"},[_c('v-hover',{attrs:{"close-delay":"800"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"mx-2 my-2 my-sm-0 text-center animate__animated animate__zoomIn"},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-card',{staticClass:"mb-2 pa-3",class:{ 'animate__animated animate__wobble': hover },attrs:{"color":"blue","dark":"","shaped":""}},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("fas fa-pencil-ruler")])],1)],1),_c('div',{staticClass:"font-weight-bold",staticStyle:{"font-size":"1.5rem"}},[_vm._v(" Tailored ")]),_c('div',{staticClass:"mx-auto",staticStyle:{"max-width":"225px"}},[_vm._v(" Custom solutions from beginning to end ")])])]}}])}),_c('v-hover',{attrs:{"close-delay":"800"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"mx-2 my-2 my-sm-0 text-center animate__animated animate__zoomIn"},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-card',{staticClass:"mb-2 pa-3",class:{ 'animate__animated animate__wobble': hover },attrs:{"color":"blue","dark":"","shaped":""}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("fas fa-tachometer-alt")])],1)],1),_c('div',{staticClass:"font-weight-bold",staticStyle:{"font-size":"1.5rem"}},[_vm._v(" Fast ")]),_c('div',{staticClass:"mx-auto",staticStyle:{"max-width":"225px"}},[_vm._v(" Minimal load times without any lag ")])])]}}])})],1)])]),_c('div',{staticClass:"mb-16 mt-0 mt-md-14 text-center"},_vm._l((_vm.tags),function(entity,index){return _c('span',{key:index},[_c('v-hover',{attrs:{"close-delay":"800"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-chip',{staticClass:"ma-1 animate__animated font-weight-medium",class:{ animate__rollOut: hover, animate__rollIn: !hover },attrs:{"dark":"","color":"blue"}},[_vm._v(_vm._s(entity))])]}}],null,true)})],1)}),0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex justify-center mt-16 mb-12",staticStyle:{"font-size":"3rem"}},[_c('div',{staticClass:"animate__animated animate__bounceInUp",staticStyle:{"border-bottom":"4px solid #2196F3"}},[_vm._v(" About ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-4 align-self-center align-self-md-start text-center text-md-start animate__animated animate__zoomIn",staticStyle:{"max-width":"600px"}},[_vm._v(" I am Enar a full-stack web developer from Estonia. Born in "),_c('a',{staticClass:"font-weight-bold text-decoration-none",attrs:{"href":"https://www.google.com/search?q=P%C3%A4rnu,%20Estonia","target":"NewWindow"}},[_vm._v("Pärnu")]),_vm._v(" living in Tallinn. ")])}]

export { render, staticRenderFns }